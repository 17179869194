:root {
    --primary: #007bff;
    --primary-light: #89abe3;
    --secondary-dark: #ce25fc;
    --secondary-light: #f48ed5;
    --success: rgb(91, 191, 33);
    --error: #f96167;
    --error-blur: #fe9da1cc;
    --warning: rgb(199, 199, 19);
    --typography-secondary: #70727e;
    --hover-default: #e3f4ffcb;
    --border-default: #e0e0e0;
    --border-dark: #eeeeee;
    --background-light: rgba(217, 217, 217, 0.3);
}

.swingShadow {
    animation: swingShadow linear 3s infinite;
}
@keyframes swingShadow {
    0%,
    100% {
        box-shadow: 0px -1px 0px 0px var(--primary-light);
    }
    15% {
        box-shadow: 1px 0px 0px 0px var(--primary-light);
    }
    30% {
        box-shadow: 0px 1px 0px 0px var(--primary-light);
    }
    45% {
        box-shadow: -1px 0px 0px 0px var(--primary-light);
    }
}

.alertBox {
    animation: alertBox 6s;
    animation-timing-function: linear;
}

@keyframes alertBox {
    0% {
        bottom: 0rem;
    }
    5%,
    95% {
        bottom: 2rem;
    }
    100% {
        bottom: 0rem;
    }
}

.dot_blick {
    animation: dotBlink 1.5s infinite linear;
}
.dot_blick:nth-child(2) {
    animation-delay: 330ms;
}
.dot_blick:nth-last-child() {
    animation-delay: 150ms;
}

@keyframes dotBlink {
    0%,
    100% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
}

.react-pdf__Page,
.react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 8px;
}
.react-pdf__Document {
    padding: 10px 0px;
}
.react-pdf__Page__canvas {
    width: 90% !important;
    height: 90% !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}
